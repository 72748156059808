import React, { useState } from "react";

import { IoEarthOutline } from "react-icons/io5";
import { BsTruck } from "react-icons/bs";
import { Col, Container, Row, Button, Modal } from "react-bootstrap";

import "./style.css";

function MyModalSalesPoint(props: any) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modalMapContainer">
          <img
            src={require("../../../assets/icons/flamefield_world.webp")}
            alt=""
            className="modalMapImage"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyModalDistribution(props: any) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modalMapContainer">
          <img
            src={require("../../../assets/icons/flamefield_world_transport.webp")}
            alt=""
            className="modalMapImage"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export function InfoField() {
  const [salesmodalShow, setSalesModalShow] = useState(false);
  const [distributionModalShow, setDistributionModalShow] = useState(false);

  return (
    <>
      <div className="infosContainer">
        <Container className={"highContainer"}>
          <Row>
            <Col className="col-lg-3 col-12">
              <div
                className="itemContainer"
                onClick={() => setSalesModalShow(true)}
              >
                <div className="iconContainer">
                  <IoEarthOutline className="icon" />
                </div>

                <p className="firsText">+50</p>
                <p className="infoText">Global Sales Points</p>
              </div>
            </Col>
            <Col className=" col-lg-3 col-12">
              <a href="/site-map#products">
                <div className="itemContainer">
                  <div className="itemImageContainer">
                    <img
                      src={require("../../../assets/icons/pro-range.png")}
                      alt=""
                      className="itemImage"
                    />
                  </div>

                  <p className="firsText">+100</p>
                  <p className="infoText">Product Range</p>
                </div>
              </a>
            </Col>
            <Col className="col-lg-3 col-12">
              <div
                className="itemContainer"
                onClick={() => setDistributionModalShow(true)}
              >
                <div className="iconContainer">
                  <BsTruck className="icon" />
                </div>

                <p className="firsText">Global</p>
                <p className="infoText">Distribution Network</p>
              </div>
            </Col>
            <Col className="col-lg-3 col-12">
              <a
                href="https://flamefield.dearportal.com/Account/Login?ReturnUrl=%2F"
                target={"_blank"}
                rel="nofollow"
              >
                <div className="itemContainer">
                  <div className="itemImageContainer">
                    <img
                      src={require("../../../assets/icons/b2b.png")}
                      alt=""
                      className="itemImage"
                    />
                  </div>

                  <p className="firsText">B2B</p>
                  <p className="infoText">NetWork</p>
                </div>
              </a>
            </Col>
          </Row>
        </Container>
      </div>
      <MyModalSalesPoint
        show={salesmodalShow}
        onHide={() => setSalesModalShow(false)}
      />
      <MyModalDistribution
        show={distributionModalShow}
        onHide={() => setDistributionModalShow(false)}
      />
    </>
  );
}
