import React, { useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { FieldFeedback } from "../../../../../contact/components/body";
import "./style.css";

interface ErrorList {
  [fieldName: string]: string[];
}

export default function BecomeBody() {
  const [telephone, setTelephone] = useState("");
  const [mail, setMail] = useState("");
  const [address, setAdress] = useState("");
  const [errors, setErrors] = useState<ErrorList | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const captchaRef = useRef<ReCAPTCHA>(null);

  const telephoneChange = (e: any) => {
    setTelephone(e.target.value);
  };

  const mailChange = (e: any) => {
    setMail(e.target.value);
  };

  const addressChange = (e: any) => {
    setAdress(e.target.value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const name = "Wholesaler Request";
    const subject = "Wholesaler Request";
    const message = `Address: ${address}`;

    setErrors(null);
    setSuccessMessage(null);

    const captchaResult = captchaRef.current?.getValue();
    if (!captchaResult) {
      // FIXME: Internationalization on the error message
      setErrors({
        captcha: ["You didn't complete the CAPTCHA."],
      });
      return;
    }

    var formData = new FormData();
    formData.append("name", name);
    formData.append("phone", telephone);
    formData.append("email_address", mail);
    formData.append("subject", subject);
    formData.append("message", message);
    formData.append("g-recaptcha-response", captchaResult);

    const response = await fetch(
      "https://flamefield.dearportal.com/Account/Login?ReturnUrl=%2Fcontactform/submit/",
      {
        method: "POST",
        mode: "cors",
        body: formData,
      }
    );

    if (!response.ok) {
      if (response.status === 400) {
        setErrors(await response.json());
      } else {
        setErrors({
          non_field_errors: [
            "An unknown error occurred. Please try again later.",
          ],
        });
      }

      return;
    }

    setSuccessMessage(
      "Successfully sent your message. We will contact you in a few days."
    );
  };

  return (
    <Container>
      <div className="borderTop"></div>
      <Row className="">
        <div className="d-flex justify-content-center">
          <p className="becomeHeadText">Become a Wholesaler...</p>
        </div>
      </Row>
      <Row className="">
        <Col className="col-12 d-flex justify-content-center">
          <div className="formContainer">
            <Form noValidate onSubmit={handleSubmit}>
              <FieldFeedback errors={errors} fieldName="non_field_errors" />
              <Form.Group className="mb-3 becomeFormGroup ">
                <Form.Label className="becomeFormText">
                  Telephone Number
                </Form.Label>
                <Form.Control
                  className="becomeFormText formControl "
                  type="text"
                  placeholder="Enter telephone number"
                  value={telephone}
                  onChange={telephoneChange}
                  isInvalid={!!errors?.phone}
                />
                <FieldFeedback errors={errors} fieldName="phone" />
              </Form.Group>
              <Form.Group className="mb-3 becomeFormGroup">
                <Form.Label className="becomeFormText">
                  Email address
                </Form.Label>
                <Form.Control
                  className="becomeFormText formControl"
                  type="email"
                  placeholder="Enter email"
                  value={mail}
                  onChange={mailChange}
                  isInvalid={!!errors?.email_address}
                />
                <FieldFeedback errors={errors} fieldName="email_address" />
              </Form.Group>

              <Form.Group className="mb-3 becomeFormGroup">
                <Form.Label className="becomeFormText">Adress</Form.Label>
                <Form.Control
                  className="becomeFormText formControl"
                  as={"textarea"}
                  rows={3}
                  placeholder="Address"
                  value={address}
                  onChange={addressChange}
                  isInvalid={!!errors?.message}
                />
                <FieldFeedback errors={errors} fieldName="message" />
              </Form.Group>
              <ReCAPTCHA
                sitekey={`${process.env.REACT_APP_SITE_KEY}`}
                ref={captchaRef as any}
              />
              <FieldFeedback errors={errors} fieldName="captcha" />

              <Button
                variant="outline-secondary"
                type="submit"
                className="contactSendBtn"
              >
                Send
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
